import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    selectId: 0,
    ohx_TokenFlag: false,
    selectedItem: null,
    isLog: false,
    showMyPartInfo: false,
    showMyFleet: false,
    showMyArea: false,
    showAreaGroupRename: false,
    selectedGroupId: null,
    selectedGroupName:null,
    showAreaEditor: false,
    showSearchBoat: false,
    showAreaData: {show:false,regionId:null},
    selectedArea:{
        regionId: null,
        groupId: null,
        regionName: null,
        maxLat: null,
        maxLon: null,
        minLat: null,
        minLon: null,
        tag:1,
        conditions:""
    },

    showJoinShip:{
        show:false,
        mmsi:null
    },

    showFleetEditor:{
        show:null,
        fleetName:null,
        fleetId:null
    },

    showFleetData:{
        show:null,
        fleetId:null
    },
    showMyWarning:false,

    showWarningEditor:{
        show:null,
        warningId:null,
        warningName: null,
    },

    showWarningGroupRename:{
        show:null,
        groupName:null,
        groupId:null
    },

    showWarningData: {show:false,warningItem:null},
    showShipLabel:{
        show: false,

    },

    showWeixinPushSetting:{
        show:false,
    },
    showNearbyShipData: {show:false},
    portBoatsShow: {show:false},

  },

  getters: {
    getohx_TokenFlag(state) {
      return state.ohx_TokenFlag;
    },
  },
  mutations: {
    SET_SHOW_PORTBOATS(state, payload) {

        state.portBoatsShow.show = payload.show;
    },

    SET_SHOW_NEARBYSHIPDATA(state, payload) {

        state.showNearbyShipData.show = payload.show;
    },

    SET_SHOW_WEIXINPUSHSETTING(state, payload){
        state.showWeixinPushSetting.show = payload.show
    },
    SET_SHOW_SHIPLABEL(state, payload){
        state.showShipLabel.show = payload.show
        state.showShipLabel.mmsi = payload.mmsi
        console.log(payload)
    },


    SET_SHOW_WARNINGDATA(state, payload){
        state.showWarningData.show = payload.show
        state.showWarningData.warningItem = payload.warningItem
        // state.showWarningData.name = payload.name
    },
    SET_SHOW_WARNINGGROUPRENAME(state, payload){
        state.showWarningGroupRename.show = payload.show
        state.showWarningGroupRename.groupName = payload.groupName
        state.showWarningGroupRename.groupId = payload.groupId
    },
    SET_SHOW_WARNINGEDITOR(state, payload){
        state.showWarningEditor.show = payload.show
        state.showWarningEditor.warningId = payload.warningId
        state.showWarningEditor.warningName = payload.warningName
    },

    SET_SHOW_FLEETDATA(state, payload){
        state.showFleetData.show = payload.show
        state.showFleetData.fleetId = payload.fleetId
    },

    SET_SHOW_FLEETEDITOR(state, payload){
        state.showFleetEditor.show = payload.show
        state.showFleetEditor.fleetName = payload.fleetName
        state.showFleetEditor.fleetId = payload.fleetId
    },
    SET_SHOW_JOINSHIP(state, payload){
        state.showJoinShip.show = payload.show
        state.showJoinShip.mmsi = payload.mmsi
    },
    SET_SHOW_AREADATA(state, payload) {

      // state.showAreaData.show = payload.show;
      // state.showAreaData.regionId = payload.regionId
      state.showAreaData = payload
    },
    SET_SHOW_SEARCHBOAT(state, payload) {
      if (payload) {
        this.commit("SET_SIDE_BAR_CLOSE");
      }
      state.showSearchBoat = payload;
    },
    // SET_SHOW_AREADATA(state, payload) {
    //   state.showAreaData = payload;
    // },

    SET_SHOW_AREAEDITOR(state, payload) {
      state.showAreaEditor = payload.show;
      state.selectedArea.regionId = payload.regionId
      state.selectedArea.groupId = payload.groupId
      state.selectedArea.regionName = payload.regionName
      state.selectedArea.maxLat = payload.maxLat
      state.selectedArea.maxLon = payload.maxLon
      state.selectedArea.minLat = payload.minLat
      state.selectedArea.minLon = payload.minLon
      state.selectedArea.tag = payload.tag
      state.selectedArea.conditions = payload.conditions
    },

    SET_SIDE_BAR_CLOSE(state) {
      state.showMyArea = false;
      state.showMyFleet = false;
      state.showSearchBoat = false;
      state.showMyWarning = false
    },
    SET_SHOW_MYFLEET(state, payload) {
      if (payload) {
        this.commit("SET_SIDE_BAR_CLOSE");
      }

      state.showMyFleet = payload;
    },
    SET_SHOW_MYAREA(state, payload) {
      if (payload) {
        this.commit("SET_SIDE_BAR_CLOSE");
      }

      state.showMyArea = payload;
    },

    SET_SHOW_MYWARNING(state, payload){
        if(payload){
            this.commit("SET_SIDE_BAR_CLOSE");
        }
        state.showMyWarning = payload
    },
    SET_SHOW_AREAGROUPRENAME(state, payload) {
      state.showAreaGroupRename = payload.show;
      state.selectedGroupId = payload.groupId;
      state.selectedGroupName = payload.groupName;
    },
    refreshohx_TokenFlag(state, payload) {
      state.ohx_TokenFlag = payload;
    },
    SET_SELECTED_ITEM(state, item) {
      state.selectedItem = item;
    },
    SET_LOGIN_STATE(state, value) {
      state.isLog = value;
    },
    SET_PART_INFO_STATE(state, value) {
      state.showMyPartInfo = value;
    },
  },
  actions: {
    setSelectedItem({ commit }, item) {
      commit("SET_SELECTED_ITEM", item);
    },
  },
  modules: {},
});
